export class Colors {
  primary: string = '#2b4c3c';
  primary_dark: string = '#2b4c3c';
  secondary: string = '#4e7944';
  danger: string = '#ef4453';
  warning: string = '#fdcc17';
  light: string = '#f8f8f8';
  gray: string = '#808080';
  dark: string = '#222';
  black: string = '#000000';
  white: string = '#ffffff';
  primary_contrast: string = '#ffffff';
  toolbar = '#F8F8F8';
}
export class AppConfig {
  colors: Colors = new Colors();
  title: string = 'CURRENT';
  appID: string = 'eco.current';
  appHost: string = 'app.current.eco';
  showTitle: boolean = false;
  defaultLang: string = 'en';
  appToken: string = null;
  readonly isWhitelabel: boolean = this.appToken ? true : false;
  root: string = 'https://scpay.smartcharge.io';
  pushAppID: string = '944eae00-c192-4275-8a90-1b85b6c35a8c';
  pushSenderID: string = '693882642625';
  stationIcon: StationIcon = { height: 40, width: 40 };
  toolbarLogoHeight: string = '1.2em';
  availableLangs: string[] = ['en', 'nb', 'de', 'da', 'sv'];
  privacyPolicy: any = {
    nb: 'https://www.current.eco/company/privacy-policy',
    en: 'https://www.current.eco/company/privacy-policy'
  };
  termsAndConditions: any = {
    nb: 'https://www.current.eco/company/terms-and-conditions',
    en: 'https://www.current.eco/company/terms-and-conditions'
  };
  contact: any = {
    external: false,
    email: '',
    phone: '',
    //web: 'current.eco',
    customerService: {
      nb: 'https://www.current.eco/no-no/support',
      en: 'https://www.current.eco/en/support'
    }
  };
  constructor() {}
}
interface StationIcon {
  height: number;
  width: number;
}
